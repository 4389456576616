<template>
    <router-view />
</template>

<script lang="js">

export default {
    name: 'SignedInApp',

    data() {
        return {
            //
        }
    },

    methods: {
        signOut() {
            this.$store.commit('signOut');
            this.$store.commit('resetDataDerivedFromUserInput'); // TODO: Probably not necessary?
            //localStorage.removeItem('selectedCompanyUuid');
            localStorage.removeItem('authorizationBearer');

            axios.defaults.headers.common['Authorization'] = '';
            axios.defaults.params = {};

            // TODO: The below code only works because of the catch. Figure out what was wrong before.
            this.$router.push('/login').catch(() => true);
            window.location.reload(); // TODO: Empty store gracefully instead
            
        },

        switchToCompanyDashboard(companyUuid) {
            this.$store.dispatch('getUserData', { companyUuid }).then((response) => {
                if (response.data.company) {
                    const companyUuid = response.data.company.uuid;

                    const finishedSetup = this.$store.getters.companyInitialisationComplete;
                    if (this.$route.name !== 'CompanySetup' && !finishedSetup && this.$store.getters.quickSetupAvailableForCompany) {
                        this.$router.push({ name: 'CompanySetup', params: { companyUuid } });
                        return;
                    }

                    this.goToCompanyDashboard(companyUuid);
                } else {
                    this.$router.push({ name: 'CompanyLessDashboard' });
                }
            }).catch(error => {
                this.handleUserDataError(error);
            });
        },

        goToCompanyDashboard(companyUuid) {
            this.$router.push({ name: 'Dashboard', params: { companyUuid: companyUuid } });
        },

        setAxiosAuthorizationBearer(token) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        },

        getCompanyUuidFromStorage() {
            if (this.sessionStorage.exists('selected-company-uuid')) {
                return this.sessionStorage.get('selected-company-uuid');
            } else if (localStorage.selectedCompanyUuid) {
                return localStorage.getItem('selectedCompanyUuid');
            } else {
                return null;
            }
        },

        handleUserDataError(error) {
            console.log('handle');
            if (error?.response.status === 403) {
                console.log('handle 403');
                this.sessionStorage.remove('selected-company-uuid');
                localStorage.removeItem('selectedCompanyUuid');
            }
        },
    },

    computed: {
        company () {
            return this.$store.getters.company;
        },
    },

    watch: {
        company (newCompany, oldCompany) {
            if (newCompany) {
                this.sessionStorage.set('selected-company-uuid', newCompany.uuid);
                localStorage.setItem('selectedCompanyUuid', newCompany.uuid);

                this.$root.$emit('received-company-data');
            }
        },
    },

    created() {
        this.$root.$on('sign-out-user', this.signOut);

        axios.interceptors.response.use((response) => {
            return response;
        }, (error) => {
            if (error.response.status == 401) {
                console.log('401 intercepted');
                this.signOut();
            }

            if (error.response.status == 403) {
                if (error.response.data.reason === 'insufficient_subscription') {
                    const params = {
                        reason: 'insufficient_subscription',
                        requiresOneOfPackages: error.response.data.available_in_packages,
                    };

                    this.$router.push({ name: 'Forbidden', params});
                } else if (error.response.data.reason === 'incompatible_company_type') {
                    this.$router.push({ name: 'Forbidden', params: { reason: error.response.data.reason }});
                } else {
                    this.$router.push({ name: 'Forbidden' });
                }
            } else if (error.response.status === 401) {
                this.$router.push('/login');
            }

            return Promise.reject(error);
        });

        const impersonatingUuid = window.sessionStorage.getItem('impersonatingUuid');
        const impersonatingName = window.sessionStorage.getItem('impersonatingName');
        if (impersonatingUuid && impersonatingName) {
            this.$store.commit('setImpersonatingUser', { uuid: impersonatingUuid, name: impersonatingName });
            axios.defaults.params = { impersonating: impersonatingUuid };
        }

        let token = localStorage.authorizationBearer;

        if (token) {
            this.setAxiosAuthorizationBearer(token);

            const companyUuid = this.$route.params.companyUuid ?? this.getCompanyUuidFromStorage() ?? null;

            this.$store.dispatch('getUserData', { companyUuid }).then((response) => {
                if (this.$route.name === 'root') {
                    if (response.data.company) {
                        this.goToCompanyDashboard(response.data.company.uuid);
                    } else {
                        this.$router.push({ name: 'CompanyLessDashboard' });
                    }
                }

                this.$root.$emit('received-initial-user-data');
            }).catch(error => {
                this.handleUserDataError(error);
            });
        } else {
            this.$router.push('/login');
        }
    },

    mounted() {
        let authorizationBearer = localStorage.authorizationBearer;

        if (authorizationBearer) {
            this.showError = false;
            this.setAxiosAuthorizationBearer(authorizationBearer);
        }
    },

    beforeDestroy() {
        this.$root.$off('sign-out-user', this.signOut);
    }
}
</script>

<style lang="scss">


</style>
