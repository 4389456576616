<template>
    <sup><i class="bi bi-key-fill ms-1" :class="['text-' + iconColour]"></i></sup>
</template>

<script>
export default {
    name: 'WsyKey',

    props: {
        iconColour: {
            type: String,
            default: 'warning',
        }
    }
}
</script>