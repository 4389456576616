<template>
    <div class="dropdown language-dropdown" :class="thin ? '' : 'col-sm-4 col-md-4 col-lg-3 col-xl-2 pe-2'"  :style="stylex">
        <img :src="$store.getters.getFlagUrl($store.state.language.selectedLanguage)" :width="width ? width : ''" :class="classg ? classg : ''"
            alt="Selected language flag" data-bs-toggle="dropdown" aria-expanded="false" />
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li v-for="lang in languages" :key="lang" @click="changeLanguage(lang)">
                <a href="#" class="dropdown-item ps-1" :class="{ 'active': $store.state.language.selectedLanguage === lang }">
                    <img :src="$store.getters.getFlagUrl(lang)" :alt="lang.toUpperCase() + ' flag'" />
                    {{ langToLanguage[lang] }}
                </a>
            </li>
        </ul>
    </div>
</template>

<script lang="js">

export default {
    name: 'LanguageDropdown',
    props: {
        // update cashier table DB
        updateDb: {
            type: Boolean,
            default: false
        },
        // drop styles/classes if chosen
        thin: {
            type: Boolean,
            default: false,
        },
        // add class
        classg: {
            type: String,
            default: '',
            required: false
        },
        width: {
            type: String,
            default: '',
            required: false
        },
        // set style
        stylex: {
            type: String,
            default: ''
        }

    },
    data() {
        return {
            languages: [
                'da',
                'en',
            ],
            langToLanguage: {
                da: 'Danish',
                en: 'English',
            },
        }
    },

    methods: {
        async changeLanguage(language) {
            this.$store.dispatch('setLanguage', language)

            if (!this.updateDb) {
                return;
            }
            
            const uuid = this.$store.getters.cashier.uuid;
            const response = await this.wsyAPI.post('cashiers/' + uuid + '/language', { language: language });

            if (!this.wsyAPI.validateResponse(response)) {
                this.$root.$emit('show-error', response.message);
            }
        },
    }
}
</script>

<style lang="scss">
@import "./src/assets/palettes/_standard.scss";

.language-dropdown {
    max-width: 25%;

    button {
        padding: 0;
        border: 0;
    }

    img.flag-selected {
        cursor: pointer;
        object-fit: cover;
    }

    .dropdown-item {
        img {
            height: 1.5rem;
        }
    }
}

.dropdown {
    button {
        border-radius: 0.5rem !important;
        background-color: $dark-1 !important;
        color: $light-1 !important;

        &:hover, &.show {
            >.bi-caret-down-fill {

                color: $turquoise-1 !important;
            }
        }
    }

    .dropdown-menu {
        border-radius: 0.5rem !important;
    }
}

</style>